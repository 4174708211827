import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useStatusProvider } from './StatusProvider';
import { BICONOMY_API_KEY, PROVIDERS } from '../constants/common';
import { createBundler, createSmartAccountClient, PaymasterMode } from '@biconomy/account';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';

const generateBiconomyBundlerUrl = chainId => {
  const baseUrl = process.env.REACT_APP_BICONOMY_BUNDLER_URL;
  const apiKey = BICONOMY_API_KEY[chainId];
  if (!apiKey) {
    throw new Error(`No Biconomy API key found for chain ID ${chainId}`);
  }
  return baseUrl.replace('{chainId}', chainId).replace('{apiKey}', apiKey);
};

const createConfig = (privateKey, chainId = 80002) => ({
  privateKey,
  biconomyPaymasterApiKey: BICONOMY_API_KEY[chainId],
  bundlerUrl: generateBiconomyBundlerUrl(chainId),
  rpcUrl: PROVIDERS[chainId],
});

const BiconomyContext = createContext({
  biconomyTransferToken: () => Promise.resolve(undefined),
  getSmartAccountAddress: () => Promise.resolve(undefined),
});

export function BiconomyProvider({ children }) {
  const { t } = useTranslation('common');
  const [smartAccountAddress, setSmartAccountAddress] = useState();
  const { addMessage, setIsLoading } = useStatusProvider();

  const createSmartWallet = useCallback(async (privateKey, chainId) => {
    try {
      const config = createConfig(privateKey, chainId);
      const bundler = await createBundler({
        bundlerUrl: config.bundlerUrl,
        chainId: chainId,
        userOpReceiptMaxDurationIntervals: {
          [chainId]: 60000,
        },
      });

      let provider = new ethers.providers.JsonRpcProvider(config.rpcUrl);
      let signer = new ethers.Wallet(config.privateKey, provider);

      const smartWallet = await createSmartAccountClient({
        signer,
        biconomyPaymasterApiKey: config.biconomyPaymasterApiKey,
        bundler,
      });

      const saAddress = await smartWallet.getAccountAddress();
      setSmartAccountAddress(saAddress);

      return { smartWallet, provider, saAddress };
    } catch (error) {
      throw error;
    }
  }, []);

  const biconomyTransferToken = useCallback(
    async (privateKey, chainId, amount, recipientAddress = process.env.REACT_APP_ADMIN_ADDRESS) => {
      setIsLoading(true);
      try {
        const { smartWallet, provider, saAddress } = await createSmartWallet(privateKey, Number(chainId));

        const amountInWei = ethers.utils.parseEther(amount).toHexString();
        // Get smart account balance
        const balance = await provider.getBalance(saAddress);
        const isInsufficientBalance = balance.lt(ethers.BigNumber.from(amountInWei));

        if (isInsufficientBalance) {
          throw new Error(t('common:error_wallet_balance_is_not_enough'));
        }

        const tx = {
          to: recipientAddress,
          data: '0x',
          value: amountInWei,
        };

        // Send the transaction and get the transaction hash
        const userOpResponse = await smartWallet.sendTransaction(tx, {
          paymasterServiceData: { mode: PaymasterMode.SPONSORED },
        });

        const { transactionHash } = await userOpResponse.waitForTxHash();
        // Wait for transaction confirmation
        const txReceipt = await provider.waitForTransaction(transactionHash);
        // Check if the transaction was successful
        if (txReceipt.status === 1) {
          addMessage(t('common:payment_success'));
          return transactionHash;
        } else {
          throw new Error(t('common:payment_failed'));
        }
      } catch (err) {
        const errMsg = t('common:payment_failed');
        addMessage(`${errMsg}: ${err?.message}`);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [addMessage, setIsLoading, createSmartWallet],
  );

  const getSmartAccountAddress = useCallback(
    async (privateKey, chainId) => {
      try {
        const smartWalletInfo = await createSmartWallet(privateKey, Number(chainId));
        return smartWalletInfo;
      } catch (err) {
        throw err;
      }
    },
    [createSmartWallet],
  );

  const providerValues = useMemo(
    () => ({
      smartAccountAddress,
      biconomyTransferToken,
      getSmartAccountAddress,
    }),
    [smartAccountAddress, biconomyTransferToken, getSmartAccountAddress],
  );

  return <BiconomyContext.Provider value={providerValues}>{children}</BiconomyContext.Provider>;
}

export function useBiconomyProvider() {
  return useContext(BiconomyContext);
}
