import { Checkbox, Form, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from '../modal';
import { useWeb3Auth } from '../../contexts/web3auth/web3auth';
import { COLOR_GOOGLE_ICON, EMAIL_BLACK, EMAIL_GRAY } from '../../theme/images';
import { ButtonCustom, ButtonThirdParty } from '../button-component';
import { HeavyTextBlack } from '../text';
import { LOGIN_TYPE } from '../../constants/common';
import { useFormik } from 'formik';
import { Images } from '../../theme';
import { getLocalStorage, removeLocalStorage, setLocalStorage, STORAGE } from '../../utils/storage';
import * as yup from 'yup';
import InputType from '../inputType';

const ContentStyled = styled.div`
  padding: 35px 24px 24px;
  margin-top: 35px;
  position: relative;
  background-image: url(${`${Images.BG_PRESALE_LAYER_1}`});
  background-color: transparent;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
`;

const ImageAvatar = styled.img`
  position: absolute;
  width: 82px;
  height: 82px;
  left: 50%;
  top: -35px;
  transform: translateX(-50%);
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 15px;
  background-image: url(${`${Images.BG_PRESALE_LAYER_2}`});
  background-repeat: round;
  h1 {
    color: #011e5a;
    font-size: 25px;
    text-align: center;
    margin: 0;
  }
  .item-email {
    margin-bottom: 0px !important;
  }
  .input-email {
    margin-bottom: 0px;
    border: 2px solid #0099fe;
    box-shadow: none;
    .ant-input {
      color: #000000;
      font-weight: 400;
      font-size: 14px;
      &::placeholder {
        color: #cecfd3;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .form-btn {
    display: flex;
    flex-direction: column;
    /* gap: 16px !important; */
    margin-top: 10px;
    > button,
    > div {
      margin-bottom: 16px;
    }
  }
  .form-remember {
    display: flex;
    margin: 5px 0 0 1px;

    .form-remember-text {
      margin-left: 3px;
    }
  }
  .text-or {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: -8px 0px -8px;
    @supports (-webkit-text-stroke: 1.5px #000000) {
      color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
    }
  }
`;

const CheckBoxStyled = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid #0e69f2;
    background-color: #0e7ff2;
    box-shadow: none;
  }
  .ant-checkbox-checked {
    border: none;
    border-radius: 6px;
    box-shadow: none;
    .ant-checkbox-inner {
      border: none;
      border-radius: 6px;
      box-shadow: none;
      &::after {
        border: none;
        box-shadow: none;
        width: 17px;
        height: 14px;
        background-image: url(${`${Images.CHECKED_ICON}`});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

const LoginModal = ({ isOpen, onClose = () => {}, setModalToast, authStore }) => {
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState(false);
  const { getRulesLogin, loginMethodsActive } = authStore;
  const formik = useFormik({
    initialValues: {
      email: getLocalStorage(STORAGE.EMAIL_REMEMBER),
      remember: getLocalStorage(STORAGE.EMAIL_REMEMBER) ? true : false,
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        // eslint-disable-next-line
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, t('validation_messages:EMAIL_INVALID'))
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(40, t('validation_messages:MAX_LENGTH_30'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: ({ email, remember }) => {
      handleSubmitLogin(email);
      if (remember) {
        setLocalStorage(STORAGE.EMAIL_REMEMBER, email);
      } else {
        removeLocalStorage(STORAGE.EMAIL_REMEMBER);
      }
    },
  });

  const { loginAuth0EmailPasswordLess, loginGoogle } = useWeb3Auth();

  const { touched, handleSubmit, values, errors, setFieldValue, setFieldTouched } = formik;

  const handleCloseModal = () => {
    // formik.resetForm({});
    if (onClose) onClose();
  };

  // const handleSubmitLogin = async () => {
  const handleSubmitLogin = async value => {
    try {
      setLoading(true);
      await loginAuth0EmailPasswordLess(value, LOGIN_TYPE.WEB3_AUTH);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginGoogle = async () => {
    try {
      setLoading(true);
      await loginGoogle(LOGIN_TYPE.WEB3_AUTH);
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckRulesLogin = async () => {
    try {
      setLoading(true);
      await getRulesLogin();
    } catch (error) {
      setModalToast({
        isShow: true,
        type: 'error',
        description: t('common:common_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleCheckRulesLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          if (!loading) handleCloseModal();
        }}
        destroyOnClose
        className="login-modal-custom"
        padding={0}
        title={null}
        width={357}
        color="blue"
        closable={false}
      >
        <Spin spinning={loading}>
          <ContentStyled>
            <ImageAvatar src={Images.AVATAR_LOGIN} alt="avatar" />
            <FormContent>
              <h1>{t('auth:login.connect_to_account').toUpperCase()}</h1>
              {loginMethodsActive.LOGIN_EMAIL && (
                <>
                  <Form.Item
                    className="item-email"
                    validateStatus={errors?.email && touched?.email ? 'error' : ''}
                    help={
                      <div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />
                    }
                  >
                    <InputType
                      className="input-email"
                      prefix={
                        <img
                          src={values?.email ? EMAIL_BLACK : EMAIL_GRAY}
                          className="site-form-item-icon"
                          alt="icon"
                        />
                      }
                      placeholder={t('login.please_enter_email')}
                      name="email"
                      onChange={text => {
                        setFieldValue && setFieldValue('email', text.target.value || '');
                      }}
                      value={values?.email}
                      onBlur={() => setFieldTouched('email', true)}
                      disabled={loading}
                    />
                  </Form.Item>
                  <div className="form-remember">
                    <CheckBoxStyled
                      onChange={text => {
                        setFieldValue && setFieldValue('remember', text.target.checked);
                      }}
                      value={values?.remember}
                      isChecked={values?.remember}
                      checked={values?.remember}
                      colorScheme="teal"
                    />
                    <HeavyTextBlack className="form-remember-text">{t('login.remember_me')}</HeavyTextBlack>
                  </div>
                </>
              )}
              <div className="form-btn">
                {loginMethodsActive.LOGIN_EMAIL && (
                  <ButtonCustom color="blue" disabled={loading} onClick={handleSubmit}>
                    {t('login.connect_with_email').toUpperCase()}
                  </ButtonCustom>
                )}
                {loginMethodsActive.LOGIN_GOOGLE && (
                  <ButtonThirdParty logo={COLOR_GOOGLE_ICON} disabled={loading} onClick={handleLoginGoogle}>
                    {t('login.connect_with_google').toUpperCase()}
                  </ButtonThirdParty>
                )}
              </div>
            </FormContent>
          </ContentStyled>
        </Spin>
      </Modal>
    </>
  );
};

export default LoginModal;
