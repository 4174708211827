import styled from 'styled-components';

export const HeavyTextWhite = styled.span`
  font-family: 'DFSoGei-W5', sans-serif;
  font-size: 14px;
  font-weight: 900;
  color: #ffff;
`;

export const HeavyTextBlack = styled.span`
  font-family: 'DFSoGei-W5', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
`;

export const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: transparent;
`

export const BoldTextWhiteWithShadow = styled.div`
  font-size: ${props => props.fontSize || '11px'};
  font-weight: 900;
  color: #ffff;
  text-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 1);
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  @supports (-webkit-text-stroke: 2px #000000) {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000000;
    paint-order: stroke fill;
  }
`;

export const TextShadowCustom = ({ children, ...props }) => {
  return (  
    <HeaderStyled>
      <BoldTextWhiteWithShadow>
        {children && children}
      </BoldTextWhiteWithShadow>
    </HeaderStyled>
  );
};

export const BoldTextWhiteWithStroke = styled.span`
  font-family: 'DFSoGei-W5', sans-serif;
  font-weight: 900;
  font-size: 16px;
  text-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 1);
  @supports (-webkit-text-stroke: 3px #000000) {
    color: white;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #000000;
    paint-order: stroke fill;
  }
`;

export const BoldTextWhiteWithShadowError = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #ffff;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0px 2px 0px 0px rgba(38, 73, 145, 1);
  @supports (-webkit-text-stroke: 2px #264991) {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #264991;
    paint-order: stroke fill;
  }
`;

export const TextShadowErrorCustom = ({ children, ...props }) => {
  return (
    <HeaderStyled>
      <BoldTextWhiteWithShadowError >
        {children && children}
      </BoldTextWhiteWithShadowError>
    </HeaderStyled>
  );
};
