import { MainApi } from './endpoint';

export function getListMarketGenesis(params) {
  return MainApi.get(`/product/package-genesis`, params);
}

export function requestBuyGenesisByNativeToken(payload) {
  return MainApi.post(`/users/request-buy-genesis-nft`, payload);
}

export function cancelBuyGenesisByNativeToken(payload) {
  return MainApi.post(`/users/cancel-buy-genesis-nft`, payload);
}

export function buyGenesisByNativeToken(payload) {
  return MainApi.post(`/users/buy-genesis-by-native-token`, payload);
}

export function confirmGenesisByNativeToken(payload) {
  return MainApi.post(`/users/confirm-buy-genesis-by-native-token`, payload);
}

export function buyGenesisByCard(payload) {
  return MainApi.post(`/users/buy-genesis-by-card`, payload);
}

export function buyGenesisByCrypto(payload) {
  return MainApi.post(`/users/buy-genesis-by-crypto`, payload);
}

export function getLatestPresale(params) {
  return MainApi.get(`/product/presale`, params);
}
