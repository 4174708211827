import React, { useState } from 'react';
import { Images } from '../../theme';
import { DropdownItem, DropdownWrapper } from './styled';
import { Space } from 'antd';
import { HeavyTextWhite } from '../text';
import { useTranslation } from 'react-i18next';
import { setLocalStorage, getLocalStorage, STORAGE } from '../../utils/storage';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import QS from 'query-string';

const lang = getLocalStorage(STORAGE.CURRENT_LANG) || 'ja';
const ICON_LANG = {
  ja: Images.JP_FLAG,
  en: Images.EN_FLAG,
};

const LanguageDropdown = () => {
  const { i18n, t } = useTranslation('auth');
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [activeLang, setActiveLang] = useState(lang);
  const onOpenChange = value => {
    setOpen(value);
  };

  const onChangeLanguage = language => {
    if (language === activeLang) {
      setOpen(false);
      return;
    }
    i18n.changeLanguage(language, () => {
      let newPath = location.pathname.replace('/en/', '/');
      newPath = newPath.replace('/ja/', '/');
      setLocalStorage(STORAGE.CURRENT_LANG, language);
      setActiveLang(language);
      let { query } = QS.parse(location.search);
      if (query) {
        history.replace(`${newPath}?query=${query}`);
      } else {
        history.replace(newPath);
      }
    });
    setOpen(false);
  };

  const items = [
    {
      key: 'ja',
      label: (
        <DropdownItem>
          <img className="icon_flag_jp" src={Images.JP_FLAG} alt="ja flag" />
          <HeavyTextWhite>{t('login.ja_lang')}</HeavyTextWhite>
        </DropdownItem>
      ),
      onClick: () => onChangeLanguage('ja'),
    },
    {
      key: 'en',
      label: (
        <DropdownItem onclick={() => onChangeLanguage('en')}>
          <img className="icon_flag_en" src={Images.EN_FLAG} alt="en flag" />
          <HeavyTextWhite>{t('login.en_lang')}</HeavyTextWhite>
        </DropdownItem>
      ),
      onClick: () => onChangeLanguage('en'),
    },
  ];

  return (
    <DropdownWrapper menu={{ items }} placement="bottom" trigger={'click'} open={open} onOpenChange={onOpenChange}>
      <Space>
        <img className="icon_flag" src={ICON_LANG[activeLang]} alt="en flag" />
        <img className="arrow" src={Images.CHEVRON_ICON} alt="arrow" />
      </Space>
    </DropdownWrapper>
  );
};

export default LanguageDropdown;
