import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Loading from '../components/loading';
import Page from '../components/page';
// import Request from '../utils/request';
import ScrollToTop from '../utils/scrollToTop';
import { STORAGE, getLocalStorage, setLocalStorage } from '../utils/storage';
import Header from './header';
import { ROUTE_OPTION } from '../constants/routes';

const NotFound = lazy(() => import('../pages/not-found'));
const Presale = lazy(() => import('../pages/presale'));

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PrivateRoute = ({ condition, redirect, ...props }) => {
  condition = condition() || getLocalStorage(STORAGE.ACCESS_TOKEN);

  if (condition) return <Route {...props} />;
  return <Redirect to={redirect} />;
};
PrivateRoute.propTypes = {
  condition: PropTypes.func,
  redirect: PropTypes.string,
};

@withRouter
class Routes extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  _renderLazyComponent = (LazyComponent, params) => props => <LazyComponent {...props} {...params} />;
  componentDidMount() {
    sessionStorage.setItem('isFirstLogin', true);
  }

  _convertPath = path => {
    if (path) {
      const pathSplit = path?.split('/');
      return pathSplit?.[0] || path;
    }
    return path;
  };

  _getDetailRoute = () => {
    const { location } = this.props;
    const currentPath = location?.pathname;
    let pathName = currentPath.slice(4, currentPath.length);
    const detail = ROUTE_OPTION?.find(item => {
      return item?.path === this._convertPath(pathName);
    });
    return detail;
  };

  render() {
    const currentLang = getLocalStorage(STORAGE.CURRENT_LANG);
    if (!currentLang) {
      setLocalStorage(STORAGE.CURRENT_LANG, 'ja');
    }
    const routeDetail = this._getDetailRoute();
    return (
      <VerticalBox>
        {routeDetail?.name ? <Header routeDetail={routeDetail} /> : <Header />}
        <Suspense
          fallback={
            <Page>
              <Loading size="large" />
            </Page>
          }
        >
          <ScrollToTop>
            <Switch>
              <Route exact path="/:lang/" component={this._renderLazyComponent(Presale)} />
              {/* <PrivateRoute
                condition={() => Request.getAccessToken()}
                redirect="/en/landing"
                exact
                path="/:lang/"
                component={this._renderLazyComponent(Presale)}
              /> */}
              <Route path="/:lang/not-found" component={this._renderLazyComponent(NotFound)} />
              <Redirect to={`/${['ja', 'en'].includes(currentLang) ? currentLang : 'ja'}/`} />
            </Switch>
          </ScrollToTop>
        </Suspense>
      </VerticalBox>
    );
  }
}

export default Routes;
