import styled from 'styled-components';
import ModalComponent, { HeaderStyled } from '../modal';
import ButtonType from '../buttonType';
import { BG_TOAST_ERROR_ICON, BG_TOAST_ICON, ERROR_ICON, SUCCESS_ICON } from '../../theme/images';
import { TextShadowCustom } from '../text';
import { Fragment } from 'react';
import Loading from '../loading';
import TextShadow from '../text-shadow';
import { useTranslation } from 'react-i18next';

const TextHeadContent = styled.div`
  margin-block: 20px;
`;

const ContentStyled = styled.div`
  width: 100%;
  padding: 90px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  > div {
    margin-bottom: 16px;
  }
  .icon-header {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn {
    font-weight: 700;
    font-size: 14px;
  }
  .text-content {
    margin-top: 10px;
  }
`;

export default function ModalToast({
  isOpen = false,
  onClose,
  type = 'success',
  textSuccess = 'キャンセル成功',
  textFail = '失敗',
  description = '',
  label,
  loading = false,
  textTitle = '',
  textHeadContent = '',
  onOk,
}) {
  const { t } = useTranslation();
  // Set default value for label here
  const defaultLabel = label || t('common:try_again');

  function getTextBasedOnType() {
    switch (type) {
      case 'success':
        return textSuccess;
      case 'error':
        return textFail;
      default:
        return textTitle || '';
    }
  }

  return (
    <ModalComponent
      open={isOpen}
      onCancel={() => {
        onClose && onClose();
      }}
      destroyOnClose
      className="custom-modal visible"
      padding={0}
      title={null}
      width={343}
    >
      <HeaderStyled color="blue">
        <div className="title-header" data-text={textTitle}>
          {textTitle}
        </div>
      </HeaderStyled>
      {textHeadContent && (
        <TextHeadContent>
          <TextShadow fontSize="14px" colorBox="#000000">
            {textHeadContent}
          </TextShadow>
        </TextHeadContent>
      )}
      {loading ? (
        <Loading />
      ) : (
        <ContentStyled>
          {['error', 'success'].includes(type) && (
            <Fragment>
              <div className="bg-icon">
                <img
                  className="icon-header"
                  src={type === 'success' ? BG_TOAST_ICON : BG_TOAST_ERROR_ICON}
                  alt="icon"
                />
                <img className="icon-header" src={type === 'success' ? SUCCESS_ICON : ERROR_ICON} alt="icon" />
              </div>
            </Fragment>
          )}

          <TextShadowCustom colorText="#ffffff" colorBox="#000000" fontSize="16px" fontWeight="900" uppercase={true}>
            {getTextBasedOnType()}
          </TextShadowCustom>
          <TextShadow fontSize="13px" colorBox="#000000">
            {description}
          </TextShadow>
          <ButtonType
            fontSize="14px"
            fontWeight="700"
            w="180px"
            className="btn"
            typeBtn="green"
            onClick={() => {
              onOk ? onOk() : onClose();
            }}
          >
            {defaultLabel && defaultLabel}
          </ButtonType>
        </ContentStyled>
      )}
    </ModalComponent>
  );
}
