import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Component, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import QS from 'query-string';

import Clickable from '../components/clickable';
import Typography from '../components/typography';
import { Colors, Images } from '../theme';
import Media from '../utils/media';
import ChainBox from './chain-box';
import LoginModal from '../components/modal/login-modal';
import UserBox from './user-box';
import TextShadow from '../components/text-shadow';
import LanguageDropdown from '../components/language-dropdown';
import { BoldTextWhiteWithShadow } from '../components/text';
import { STORAGE, getLocalStorage, setLocalStorage } from '../utils/storage';
import { useAuth } from '../hooks/auth';
import ModalToast from '../components/modal/modal-toast';
import MaskLoading from '../components/mask-loading';
import { isTokenExpired } from '../utils/jwt';
import { MobXProviderContext } from 'mobx-react';

const HeaderContainer = styled.header`
  width: 100%;
  background: #0c2649;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.2s;
  font-weight: bold;
  background-color: #013fb5;
  min-height: 60px;
  .display-none {
    display: none !important;
  }

  &.header-detail {
    background-image: none;
    background-color: transparent;
    width: 100%;
    aspect-ratio: 0;
    box-shadow: none;
  }

  .text-right {
    text-align: right;
  }

  .wrap-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 28px;
    ${Media.lessThan(Media.SIZE.SM)} {
      padding: 10px 17px;
    }
  }

  .left-box {
    display: flex;
    flex: 1;

    ${Media.lessThan(Media.SIZE.LG)} {
      .menu-button {
        margin-left: 3px;
        margin-right: 15px;

        img {
          width: 17px;
        }
      }
    }

    .logo {
      z-index: 2;
      align-items: center;
      display: flex;
      color: #fff;
      max-height: 100%;
      width: auto;
      min-width: 130px;
      height: 38px;

      img {
        padding-right: 25px;
        height: 48px;
        width: 100%;
        object-fit: contain;
        ${Media.lessThan(Media.SIZE.XXXS)} {
          max-width: 100px;
          height: 50px;
        }
        ${Media.lessThan(Media.SIZE.LG)} {
          max-width: 150px;
          z-index: 99;
        }
      }
    }

    .menu-list {
      display: flex;
      height: 100%;

      ${Media.lessThan(Media.SIZE.LG)} {
        display: none;
      }

      .menu-item {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${Colors.TEXT};
        white-space: nowrap;
        padding: 0 10px;
        cursor: pointer;
        &.menu-item-ja {
          ${Media.lessThan(Media.SIZE.XL)} {
            padding-right: 10px;
            word-spacing: -20px;
            padding-left: 10px;
            padding-right: 0;
          }
        }
        .sub-name {
          position: absolute;
          right: 0;
          top: 20px;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 10px;
          border-radius: 2px;
          line-height: 14px;
        }

        .typography {
          white-space: nowrap;
          color: ${Colors.TEXT};
          font-weight: bold;
          font-size: 16px;

          ${Media.lessThan(Media.SIZE.XL)} {
            font-size: 14px;
          }
        }

        &:hover {
          opacity: 0.5;
        }

        &.active {
          color: #3cc8fc;

          p,
          .typography {
            color: #3cc8fc;
          }
        }

        ${Media.lessThan(Media.SIZE.XL)} {
          font-size: 14px;
        }
      }
    }

    .menu-external {
      font-size: 16px;
    }
  }

  .language-box {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .right-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex: 1;
    ${Media.lessThan(Media.SIZE.LG)} {
      .menu-list {
        display: none;
      }
    }

    .menu-button {
      margin-left: 20px;
      width: 20px;
      justify-content: center;
      display: flex;
      align-items: center;

      img {
        filter: brightness(0) invert(1);
      }
    }

    .login-button {
      border: none;
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: transparent;
      padding: 0;

      cursor: pointer;
      &-text {
        font-weight: 900;
        font-size: 14px;
        color: #ffffff;
        @supports (-webkit-text-stroke: 3px #000000) {
          -webkit-text-stroke-width: 3px;
          -webkit-text-stroke-color: #000000;
          paint-order: stroke fill;
        }
      }
    }
  }

  .center-box {
    ${Media.greaterThan(Media.SIZE.MD)} {
      display: none;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: transparent;
    &_breads {
      display: flex;
      align-items: center;
    }
  }

  .menu-panel {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    ${Media.lessThan(Media.SIZE.LG)} {
      top: 60px;
    }

    .top-menu-box {
      .menu-item {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px rgba(255, 255, 255, 0.15);
        height: 56px;
        align-items: center;
        padding: 0 20px;

        .typography {
          color: #ffffff;
        }

        > img {
          width: 8px;
        }

        &.language-item {
          display: none;
          ${Media.lessThan(Media.SIZE.LG)} {
            display: flex;
          }
        }
      }
    }

    .bottom-menu-box {
      padding: 20px;
      // background-color: ${Colors.BLACK_2};

      .bottom-menu-item {
        margin-bottom: 8px;
        display: block;
        font-size: 14px;
        color: #ffffff;

        &:hover {
          opacity: 0.9;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.open {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

@withTranslation('common')
@withRouter
@inject(stores => ({
  authStore: stores.auth,
  categoriesStore: stores.categories,
}))
@observer
class Header extends Component {
  static propTypes = {
    authStore: PropTypes.object,
    location: PropTypes.object,
    categoriesStore: PropTypes.object,
    // t: PropTypes.object,
  };

  state = {
    isOpenMenuPanel: false,
    isOpenUserPanel: false,
    isOpenSearchMobile: false,
    classFixedHeader: '',
    // isOpenLoginModal: false,
    isOpenRegisterModal: false,
  };

  _onCloseAllPanel = () => {
    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _onLogout = async e => {
    e?.preventDefault();
    try {
      MaskLoading.close(true);
      const { logoutAction } = this.props;
      await logoutAction();
      this._onCloseAllPanel();
    } catch (err) {
      MaskLoading.close(false);
    }
  };

  _onOpenForm = e => {
    const { history } = this.props;
    e.preventDefault();

    history.push('/settings');
    this._onCloseAllPanel();
  };

  _onBackHome = () => {
    const { history } = this.props;
    history.push('/');
    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
      defaultCategoryIdSelect: '',
      defaultSearchValue: '',
    });
  };

  _onToggleMenuPanel = () => {
    this.setState(state => ({
      isOpenMenuPanel: !state.isOpenMenuPanel,
      isOpenSearchMobile: false,
      isOpenUserPanel: false,
    }));
  };

  _onToggleUserPanel = () => {
    this.setState(state => ({
      isOpenUserPanel: !state.isOpenUserPanel,
      isOpenSearchMobile: false,
      isOpenMenuPanel: false,
    }));
  };

  _onToggleSearchMobile = () => {
    this.setState(state => ({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: !state.isOpenSearchMobile,
    }));
  };

  _onMenuPanelClick = item => {
    const { history } = this.props;

    history.push(item.to);

    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _renderMenuPanel = menuItems => {
    const { t, authStore } = this.props;
    const { isOpenMenuPanel } = this.state;

    const isLogin = authStore.loggedIn && authStore?.initialData?.publicAddress;

    if (!isOpenMenuPanel) return null;

    const BOTTOM_MENU_ITEMS = [
      {
        name: t('footer.about'),
        path: '/about-us',
      },
      {
        name: t('footer.faq'),
        path: '/faqs',
      },
      {
        name: t('footer.service_term'),
        path: '/service-terms',
      },
      {
        name: t('footer.privacy'),
        path: '/privacy-agreement',
      },
    ];

    return (
      <div className="menu-panel">
        <div className="top-menu-box">
          {menuItems.map((item, index) => (
            <Clickable key={index} className="menu-item" onClick={() => this._onMenuPanelClick(item)}>
              <Typography>{item.name}</Typography>
              <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
            </Clickable>
          ))}
          <div className="menu-item language-item">
            <Typography>{t('header.select_a_language')}</Typography>
          </div>
        </div>
        <div className="bottom-menu-box">
          {BOTTOM_MENU_ITEMS.map((item, index) => (
            <Link to={item.path} key={index} className="bottom-menu-item" onClick={this._onCloseAllPanel}>
              {item.name}
            </Link>
          ))}
          {isLogin && (
            <Clickable className="language-box bottom-menu-item" onClick={this._onLogout}>
              {t('header.logout')}
            </Clickable>
          )}
        </div>
      </div>
    );
  };

  _renderUserPanel = () => {
    const { t, authStore } = this.props;
    const { isOpenUserPanel } = this.state;

    const isLogin = authStore.loggedIn && authStore?.initialData?.publicAddress;

    if (!isOpenUserPanel) return null;
    return (
      <div className="menu-panel">
        <div className="top-menu-box">
          <Clickable className="menu-item" onClick={this._onOpenForm}>
            <Typography>{t('header.edit_profile')}</Typography>
            <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
          </Clickable>
        </div>
        <div className="bottom-menu-box">
          <ChainBox onSelected={this._onCloseAllPanel} />
          {isLogin && (
            <Clickable className="language-box bottom-menu-item" onClick={this._onLogout}>
              {t('header.logout')}
            </Clickable>
          )}
        </div>
      </div>
    );
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.setState({ classFixedHeader: 'header-blur-active' });
      } else {
        this.setState({ classFixedHeader: '' });
      }
    });
  }

  _onChangeLanguage = language => {
    const { i18n, location, history } = this.props;

    if (language === i18n.language) return;

    i18n.changeLanguage(language, () => {
      let newPath = location.pathname.replace('/en/', '/');
      newPath = newPath.replace('/ja/', '/');
      setLocalStorage(STORAGE.CURRENT_LANG, language);
      let { query } = QS.parse(location.search);
      if (query) {
        history.replace(`${newPath}?query=${query}`);
      } else {
        history.replace(newPath);
      }
      let element = document.getElementById('web-body');
      element.removeAttribute('class');
      element.classList.add(`${language}`);
    });
  };

  render() {
    const { authStore, t, routeDetail, history, modalToast, setModalToast } = this.props;
    const { isOpenLoginModal, toggleOpenModalLogin } = authStore;
    const { isOpenMenuPanel, classFixedHeader } = this.state;
    const MENU_ITEMS_MOBILE = [
      {
        name: t('header.nft_items'),
        to: '/search-products',
      },
    ];

    const token = getLocalStorage(STORAGE.ACCESS_TOKEN);
    const isLogin = authStore?.loggedIn && !!token;
    return (
      <div className="header">
        <HeaderContainer
          className={classnames(
            {
              open: isOpenMenuPanel,
            },
            classFixedHeader,
          )}
        >
          <div className="wrap-box">
            <div className="left-box">
              <Clickable className="logo" onClick={this._onBackHome}>
                <img src={Images.LOGO} alt="logo" />
              </Clickable>
            </div>
            <div className="right-box">
              <LanguageDropdown />
              {isLogin ? (
                <UserBox onLogout={this._onLogout} onOpenForm={this._onOpenForm} />
              ) : (
                <button className="login-button" onClick={toggleOpenModalLogin}>
                  <img className="login-button-icon" src={Images.PROFILE_ICON} alt="profile" />
                  <BoldTextWhiteWithShadow>{t('auth:login.connect_to_account')}</BoldTextWhiteWithShadow>
                </button>
              )}
              {/* <div onClick={() => this._onChangeLanguage(i18n.language === 'en' ? 'ja' : 'en')}>{i18n.language}</div> */}
            </div>

            {this._renderMenuPanel(MENU_ITEMS_MOBILE)}
            {this._renderUserPanel()}
          </div>

          <div
            className={classnames(
              { 'display-none': !routeDetail || routeDetail?.path === 'product-details' },
              'center-box',
            )}
          >
            <TextShadow
              fontSize="32px"
              strokeText="4px"
              fontWeight="900"
              colorBox="#000000"
              colorText="#ffffff"
              data-text={routeDetail?.name}
              className="center-box_sub-title"
            >
              {routeDetail?.name == 'presale' ? '' : routeDetail?.name}
            </TextShadow>
            {routeDetail?.parent && (
              <div className="center-box_breads">
                <TextShadow
                  fontSize="14px"
                  strokeText="4px"
                  fontWeight="900"
                  colorBox="#000000"
                  colorText="#969696"
                  data-text={routeDetail?.parent}
                  onClick={() => history.push(routeDetail.pathParent)}
                  className="center-box_breads_parent"
                >
                  {routeDetail?.parent}
                </TextShadow>
                <TextShadow
                  fontSize="14px"
                  strokeText="4px"
                  fontWeight="900"
                  colorBox="#000000"
                  colorText="#969696"
                  data-text="/"
                  className="center-box_breads_parent"
                >
                  /
                </TextShadow>
                <TextShadow
                  fontSize="14px"
                  strokeText="4px"
                  fontWeight="900"
                  colorBox="#000000"
                  colorText="#ffffff"
                  data-text={routeDetail?.name}
                  className="center-box_breads_child"
                >
                  {routeDetail?.name}
                </TextShadow>
              </div>
            )}
          </div>
        </HeaderContainer>
        {isOpenLoginModal && (
          <LoginModal
            onClose={toggleOpenModalLogin}
            isOpen={isOpenLoginModal}
            authStore={authStore}
            setModalToast={setModalToast}
          />
        )}
        <ModalToast
          isOpen={modalToast.isShow}
          description={modalToast?.description}
          type={modalToast.type}
          textSuccess={t('common:success')}
          textFail={t('common:fail')}
          onClose={() =>
            setModalToast({
              ...modalToast,
              isShow: false,
            })
          }
          onOk={() => {
            setModalToast({
              ...modalToast,
              isShow: false,
            });
            // modalToast && modalToast?.actionToast();
          }}
        />
      </div>
    );
  }
}

// export default Header;
export default withWeb3AuthHook(Header);

function withWeb3AuthHook(Component) {
  return function WrappedComponent(props) {
    const { logoutAction } = useAuth();
    const { auth } = useContext(MobXProviderContext);
    const ACCESS_TOKEN = getLocalStorage(STORAGE.ACCESS_TOKEN);
    const isExpired = ACCESS_TOKEN && isTokenExpired(ACCESS_TOKEN);

    useEffect(() => {
      if (isExpired) {
        logoutAction();
        auth?.setLoggedIn(false);
      }
      // eslint-disable-next-line
    }, []);

    const [modalToast, setModalToast] = useState({
      isShow: false,
      type: 'success',
      description: '',
    });
    return <Component {...props} logoutAction={logoutAction} modalToast={modalToast} setModalToast={setModalToast} />;
  };
}
